import * as _ from 'lodash'

export default () => {
  const { t } = useNuxtApp().$i18n

  const highlight = (string) => {
    if (!string) return ''
    const pattern = `<mark class="{{class}}">{{item}}</mark>`
    const marks = [
      { reg: /(『.+?』)/gim, class: 'mark-1' },
      { reg: /(［.+?］)/gim, class: 'mark-2' },
      { reg: /(「.+?」)/gim, class: 'mark-3' },
      { reg: /(【.+?】)/gim, class: 'mark-4' },
      { reg: /(《.+?》)/gim, class: 'mark-5' },
      { reg: /(\(\d+?\))/gim, class: 'mark-6' },
    ]

    let tmp = string
    for (let i = 0; i < marks.length; i++) {
      const e = marks[i]
      const p = pattern.replace(/{{class}}/gim, e.class)
      tmp = tmp.replace(e.reg, (t) => {
        return p.replace(/{{item}}/gim, t)
      })
    }

    return tmp
  }

  const formatCardsArray = (cards = []) => {
    const countMap = {}

    return cards.map((item) => {
      const id = item.id
      const count = countMap[id] ? countMap[id] + 1 : 1
      const max = cards.filter((i) => i.id === item.id).length
      countMap[id] = count

      return { ...item, count, max }
    })
  }

  const formatCardsMap = (cards = new Map()) => {
    return Array.from(cards.values())
      .map((e) =>
        Array(e.count)
          .fill(e.card)
          .map((ce, ci) => ({ ...ce, count: ci + 1, max: e.count }))
      )
      .flat()
  }

  const countSouls = (cards = []) =>
    cards.map((e) => (e.trigger.includes('soul') ? 1 : e.trigger.includes('soul2') ? 2 : 0)).reduce((a, b) => a + b, 0)

  const groupCards = (cards = [], tab = { isClimmax: false, field: '', unit: '' }) => {
    if (cards.length === 0) return []

    const climax = cards.filter((e) => e.type === 'クライマックス')
    const other = cards.filter((e) => e.type !== 'クライマックス')
    const all = cards
    let res = []

    if (tab.isClimmax) {
      const group = _.groupBy(other, tab.field)
      res = [
        climax.length > 0 ? { type: 'title', name: t('クライマックス'), count: climax.length, souls: countSouls(climax) } : null,
        ...climax,
      ].filter((e) => e)

      Object.keys(group).forEach((key) => {
        res.push({ type: 'title', name: [t(key), t(tab.unit)].join(' '), count: group[key].length, souls: countSouls(group[key]) })
        res = [...res, ...group[key]]
      })
    } else {
      const group = _.groupBy(all, tab.field)
      Object.keys(group).forEach((key) => {
        res.push({ type: 'title', name: [t(key), t(tab.unit)].join(' '), count: group[key].length, souls: countSouls(group[key]) })
        res = [...res, ...group[key]]
      })
    }

    return res
  }

  // cards is an array from formatCardsMap
  const genBuildDeckName = (cards = [], input = '') => {
    const climax = cards.filter((e) => e.type === 'クライマックス')
    // prepare cards info { series = [], count = 0, colors = [], triggers = [] }
    const series = Object.keys(_.keyBy(cards, 'seriesCode'))
    const count = cards.length
    const colors = Object.keys(_.keyBy(cards, 'color'))
    const triggers = _.entries(
      _.countBy(
        climax
          .map((e) => e.trigger)
          .flat()
          .filter((e) => e && e !== 'soul')
      )
    )

    // find $XXX$ variables to replace input with cards info
    let text = input
    text = text.replace(/\$SERIES\$/gim, series.join(','))
    text = text.replace(/\$NUM\$/gim, count)
    text = text.replace(/\$COLOR\$/gim, colors.map((e) => t(e).split('色')[0]).join(''))
    text = text.replace(/\$CX\$/gim, triggers.map((e) => `${e[1]}${t(e[0])}`).join(''))

    return text
  }

  return {
    highlight,
    formatCardsArray,
    formatCardsMap,
    groupCards,
    genBuildDeckName,
  }
}
